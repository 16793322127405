<script setup>
import stores from "@/Stores/stores.js";
import {storeToRefs} from "pinia";
import setLocale from "@/Services/setLocale.js";
import {InputDropdownPlus, handleFeedbackFromServer} from "@ryokutech/brainkit_frontend";
import {trans} from 'laravel-vue-i18n';

const {configStore} = stores();
const {activeLocale, activeObject, languages} = storeToRefs(configStore);

async function setActiveLocale(locale) {
    if (locale === activeLocale.value) {
        return;
    }

    await setLocale(locale);
    handleFeedbackFromServer({type: 'success', message: trans('language.ui.feedback.language_set')});
}
</script>

<template>
    <InputDropdownPlus
        :close-on-select="true"
        :searchable="false"
        :clearable="false"
        :value="activeLocale"
        @select="setActiveLocale($event)"
        :option-list="languages"/>
</template>
